<template>
  <div ref="mapContainer" class="map" style="height: 100vh;"></div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { supabase } from '../supabaseClient';

const mapContainer = ref(null);

// Funktion zum Laden der Punkte aus Supabase
const loadPoints = async () => {
  const { data: punkte, error } = await supabase
    .from('sperrbereiche')
    .select('id, name, latitude, longitude'); // Stelle sicher, dass die Spaltennamen korrekt sind

  if (error) {
    console.error("Fehler beim Laden der Punkte", error);
    return [];
  }
  
  return punkte.slice(0, 10); // Beschränkt auf die ersten 10 Punkte
};

// Funktion zum Anzeigen der Punkte auf der Karte
const displayPoints = (punkte) => {
  punkte.forEach(({ latitude, longitude }) => {
    if (latitude && longitude) {
      L.marker([latitude, longitude]).addTo(map);
    } else {
      console.warn('Punkt ohne gültige Koordinaten übersprungen');
    }
  });
};

let map = null;

onMounted(() => {
  map = L.map(mapContainer.value).setView([52.520008, 13.404954], 13);
  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '© OpenStreetMap contributors',
  }).addTo(map);

  loadPoints().then(displayPoints);

  const locateControl = L.control({position: 'bottomright'});
  locateControl.onAdd = function() {
    const button = L.DomUtil.create('button', 'locate-user-button');
    button.innerHTML = '📍';
    button.onclick = function() {
      map.locate({ setView: true, maxZoom: 16 });
    };

    L.DomEvent.disableClickPropagation(button);
    return button;
  };
  locateControl.addTo(map);

  map.on('locationfound', (e) => {
    L.marker(e.latlng).addTo(map)
      .bindPopup('Dein Standort').openPopup();
  });
});
</script>



<style>
/* Stellt sicher, dass die Karte und die Marker korrekt angezeigt werden */
.leaflet-container {
  height: 100%;
  width: 100%;
  z-index: 0;
}

.leaflet-marker-icon {
  background-image: url('~leaflet/dist/images/marker-icon.png') !important;
  background-size: contain !important;
  border: none !important;
}

.leaflet-marker-shadow {
  background-image: url('~leaflet/dist/images/marker-shadow.png') !important;
  background-size: contain !important;
}
.locate-user-button {
  background-color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.4);
}
</style>

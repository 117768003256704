// main.js
import { createApp } from 'vue'
import App from './App.vue'
// Importiere den Supabase Client aus deinem supabaseClient-Modul
import { supabase } from './supabaseClient'

const app = createApp(App)

// Stelle den Supabase Client deiner App zur Verfügung
app.provide('supabase', supabase)

app.mount('#app')
